import { render, staticRenderFns } from "./Hospitals.vue?vue&type=template&id=11c98545&scoped=true"
import script from "./Hospitals.vue?vue&type=script&lang=js"
export * from "./Hospitals.vue?vue&type=script&lang=js"
import style0 from "./Hospitals.vue?vue&type=style&index=0&id=11c98545&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c98545",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jissjohnson/code/Zenrion/CareKraft/zenrion-erp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11c98545')) {
      api.createRecord('11c98545', component.options)
    } else {
      api.reload('11c98545', component.options)
    }
    module.hot.accept("./Hospitals.vue?vue&type=template&id=11c98545&scoped=true", function () {
      api.rerender('11c98545', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/admin/Hospitals.vue"
export default component.exports