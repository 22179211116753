/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-md-8>
    <v-flex xs12>
      <v-layout wrap justify-start pb-4>
        <v-flex xs12 sm6 lg2 align-self-center>
          <h3>
            {{ $t("pages.hospital.all_hospital") }}
          </h3>
        </v-flex>
        <v-spacer />
        <v-flex xs12 sm6 lg4 xl3>
          <v-layout wrap justify-start>
            <v-flex xs6 md6 text-right align-self-center>
              <v-text-field
                placeholder="Search hospital"
                type="search"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
                v-model="searchKey"
              />
            </v-flex>
            <v-flex xs6 md6 align-self-center text-right>
              <AppNavButton
                :route="{ name: 'hospitals.add' }"
                custom-class="pa-5"
              >
                <template #content>
                  <v-icon size="14px">mdi-plus</v-icon>
                  Add new hospital
                </template>
              </AppNavButton>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <AppSimpleTable
        :headings="headings"
        :items="list"
        :pagination="pagination"
        empty-title="No hospitals to show"
        @paginate="(val) => (pagination.page = val)"
        @delete="toggleDelete"
      />
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      @cancel="toggleDelete"
      @submit="confirmDeleteEmployee"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Helpers from "@/libs/helper/components/helper-functions";

export default {
  name: "Hospitals",
  data: () => ({
    selectedItem: {},
    showDelete: false,
    page: 1,
    searchKey: null,
    headings: [
      { name: "#", value: "index", style: "width:80px" },
      { name: "form.name", value: "name" },
      { name: "Joined on", value: "joined" },
      { name: "no. of Pools", value: "departments" },
      { name: "no. of Nurses", value: "employees" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["edit", "delete"],
        view: "hospital",
        edit: "hospital.edit",
      },
    ],

    pagination: {
      per_page: 10,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      hospitals: "hospital/getHospitals",
    }),
    list() {
      return this.hospitals.map((itm, index) => {
        return {
          index: index + 1,
          id: itm.id,
          name: itm.name,
          joined: this.$moment(itm.created_at).format("DD MMM YYYY"),
          departments: itm.departments_count,
          employees: itm.employees_count,
        };
      });
    },
  },
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "Hospitals",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
    this.getList();
  },
  methods: {
    ...mapActions({
      deleteHospital: "admin/deleteHospital",
    }),
    toggleDelete(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    getList() {
      this.$store
        .dispatch("hospital/getHospitalList")
        .then((res) => {
          this.pagination = Helpers.setPagination(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmDeleteEmployee() {
      this.deleteHospital(this.selectedItem.id).then(() => {
        this.toggleDelete();
        this.getList();
      });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}
.v-text-field--outlined::v-deep input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}
</style>
