var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-md-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pb-4": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm6: "",
                    lg2: "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("h3", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("pages.hospital.all_hospital")) + " "
                    ),
                  ]),
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm6: "", lg4: "", xl3: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md6: "",
                            "text-right": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Search hospital",
                              type: "search",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "append-icon": "mdi-magnify",
                            },
                            model: {
                              value: _vm.searchKey,
                              callback: function ($$v) {
                                _vm.searchKey = $$v
                              },
                              expression: "searchKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs6: "",
                            md6: "",
                            "align-self-center": "",
                            "text-right": "",
                          },
                        },
                        [
                          _c("AppNavButton", {
                            attrs: {
                              route: { name: "hospitals.add" },
                              "custom-class": "pa-5",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("v-icon", { attrs: { size: "14px" } }, [
                                      _vm._v("mdi-plus"),
                                    ]),
                                    _vm._v(" Add new hospital "),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("AppSimpleTable", {
            attrs: {
              headings: _vm.headings,
              items: _vm.list,
              pagination: _vm.pagination,
              "empty-title": "No hospitals to show",
            },
            on: {
              paginate: (val) => (_vm.pagination.page = val),
              delete: _vm.toggleDelete,
            },
          }),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem },
        on: { cancel: _vm.toggleDelete, submit: _vm.confirmDeleteEmployee },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }